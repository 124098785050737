<template>
	<div id="accountInfo">
		<div class="container lp-full-height">
			<div class="row justify-content-center">
				<div class="col-xl-6 col-lg-8 col-md-8 col-sm-12 my-5">
					<div class="account-item-navigation d-flex justify-content-start">
						<router-link to="/account" class="btn btn-outline-primary btn-sm"
							><font-awesome-icon :icon="['fas', 'arrow-left']" /> Account</router-link
						>
					</div>
					<div class="account-item-image d-flex flex-column align-items-center justify-content-center mb-2">
						<img src="../../../public/images/account-notifications-header.svg" width="180" alt="" />
						<h4 class="mt-2 text-primary">Notifications</h4>
					</div>

					<div class="card">
						<div class="card-body">
							<p class="mb-4">How should we notify you when we detect site offline?</p>
							<form @submit.prevent="updateNotifications">
								<div class="form-group col-12">
									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="notifications.email"
											type="checkbox"
											id="flexSwitchCheckDefault"
										/>
										<label for="" class="form-check-label ms-2">Email</label>
									</div>
								</div>

								<div class="form-group col-12">
									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="notifications.slack"
											disabled
											type="checkbox"
											id="flexSwitchCheckDefault"
										/>
										<label for="" class="form-check-label ms-2">Slack (coming soon)</label>
									</div>
								</div>

								<div class="form-group col-12">
									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="notifications.sms"
											disabled
											type="checkbox"
											id="flexSwitchCheckDefault"
										/>
										<label for="" class="form-check-label ms-2">SMS (in progress)</label>
									</div>
								</div>

								<p class="mb-0">How often should we notify you?</p>
								<div class="form-group">
									<select class="form-select" v-model="notifications.frequency">
										<option value="instant">Instant</option>
										<option value="daily">Daily</option>
										<option value="weekly">Weekly</option>
									</select>
								</div>

								<p class="text-center mt-4">
									<button class="btn btn-primary">
										Save notifications settings
									</button>
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* import restApi from '../../api' */
import { mapState } from 'vuex'
import { handleErrors } from '../../actions/helpers'

export default {
	name: 'AccountNotifications',
	data() {
		return {
			loading: false,
			notifications: {
				email: true,
				slack: false,
				sms: false,
				frequency: 'instant',
			},
		}
	},
	computed: {
		...mapState(['account', 'storageUrl']),
	},

	created() {
		if (this.account?.notifications) {
			this.notifications = JSON.parse(this.account.notifications)
		}
	},

	watch: {
		account: function() {
			if (this.account && this.account.notifications !== null) {
				this.notifications = JSON.parse(this.account.notifications)
			}
		},
	},

	methods: {
		updateNotifications() {
			this.loading = true
			let data = {
				id: this.account.id,
				name: this.account.name,
				notifications: JSON.stringify(this.notifications),
			}

			this.$store
				.dispatch('accountUpdate', data)
				.then(() => {
					this.loading = false
					this.$swal.fire({
						icon: 'success',
						title: 'User notifications updated',
					})
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: handleErrors(error, 'userUpdate'),
						timer: 5000,
					})
				})
		},
	},
}
</script>
