<template>
	<div id="accountInfo">
		<div class="container lp-full-height">
			<div class="row justify-content-center">
				<div class="col-xl-4 col-lg-5 col-md-8 col-sm-12 my-5">
					<div v-if="account" class="text-center mb-3">
						<span @click="openDialog()">
							<Avatar role="button" :user="account" class="my-3" :size="120" />
						</span>
						<input
							type="file"
							class="d-none"
							name="avatar"
							accept="image/*"
							id="avatar"
							@change="uploadAvatar($event)"
						/>

						<h4>{{ account.name }}</h4>
						<p class="m-0 p-0">{{ account.email }}</p>
					</div>
					<!-- Account navigation info-->
					<div class="card">
						<div class="account-navigation list-group">
							<router-link class="list-group-item list-group-item-action" to="account/edit">
								🙂 Account info
							</router-link>
							<router-link
								class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
								to="monitoring"
							>
								<span>🖥️ Monitoring</span>
								<span style="width:125px"
									><div class="progress">
										<div
											class="progress-bar"
											role="progressbar"
											:style="'width:' + (monitorings.length / 5) * 100 + '%'"
											aria-valuenow="20"
											aria-valuemin="0"
											aria-valuemax="100"
										>
											{{ monitorings.length }}/5
										</div>
									</div></span
								>
							</router-link>
							<router-link class="list-group-item list-group-item-action" to="account/notifications">
								🔔 Notifications
							</router-link>
							<button class="list-group-item list-group-item-action d-flex justify-content-between">
								<span class="text-black-50">💰 Plan & Billing</span>
								<span class="text-black-50"> (coming soon) </span>
							</button>
							<router-link class="list-group-item list-group-item-action" to="account/change-password">
								🔐 Change password
							</router-link>
							<button @click="logout()" class="list-group-item list-group-item-action">🚪 Logout</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import restApi from '../../api'
import { mapState } from 'vuex'
import Avatar from '@/components/user/Avatar'
import { handleErrors } from '../../actions/helpers'

export default {
	name: 'AccountInfo',
	components: {
		Avatar,
	},
	data() {
		return {
			loading: false,
			avatar: '',
			monitorings: [],
		}
	},
	computed: {
		...mapState(['account', 'storageUrl']),
	},
	updated() {
		this.avatar = this.account.avatar ? this.storageUrl + this.account.avatar : ''
	},
	created() {
		this.getMonitorings()
	},
	methods: {
		uploadAvatar($event) {
			var form = new FormData()
			form.append('avatar', $event.target.files[0])
			form.append('id', this.account.id)

			const config = {
				headers: { 'content-type': 'multipart/form-data' },
			}

			restApi
				.post('/account/avatar', form, config)
				.then(({ data }) => {
					this.account.avatar = data
					this.avatar = data
					this.$swal.fire({
						icon: 'success',
						title: 'Avatar successfully updated',
						timer: 4000,
					})
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: handleErrors(error, 'userAvatar'),
						timer: 6000,
					})
				})
		},
		openDialog() {
			document.getElementById('avatar').click()
		},
		async logout() {
			this.loading = true
			try {
				await this.$store.dispatch('authLogout', this.token)
			} catch (error) {
				this.$swal.fire({
					icon: 'error',
					title: handleErrors(error, ''),
					timer: 6000,
				})
			} finally {
				this.loading = false
				if (this.$route.name !== 'Home') {
					this.$router.push({ name: 'Home' })
				}
			}
		},

		// Get monitorings
		getMonitorings() {
			restApi
				.get('/monitorings')
				.then(({ data }) => {
					this.monitorings = data
				})
				.catch(error => {
					console.log(error)
				})
		},
	},
}
</script>
