<template>
	<div id="accountChangePassword">
		<div class="container lp-full-height">
			<div class="row justify-content-center">
				<div class="col-xl-6 col-lg-8 col-md-8 col-sm-12 my-5">
					<div class="account-item-navigation d-flex fle justify-content-start mb-2">
						<router-link to="/account" class="btn btn-outline-primary"
							><font-awesome-icon :icon="['fas', 'arrow-left']" /> Account</router-link
						>
					</div>
					<div class="account-item-image d-flex flex-column align-items-center justify-content-center mb-2">
						<img src="../../../public/images/account-edit-header.svg" width="300" alt="" />
						<h4 class="mt-2 text-primary">Account info</h4>
					</div>

					<div class="card" v-if="account">
						<div class="card-body">
							<form @submit.prevent="updateAccount">
								<div class="mb-3 row">
									<label for="email" class="col-sm-4 col-form-label">Email</label>
									<div class="col-sm-8">
										<input
											type="email"
											readonly
											v-model="account.email"
											class="form-control"
											id="email"
											required
										/>
									</div>
								</div>

								<div class="mb-3 row">
									<label for="name" class="col-sm-4 col-form-label">Name</label>
									<div class="col-sm-8">
										<input
											type="text"
											v-model="account.name"
											class="form-control"
											id="name"
											placeholder="enter name"
											required
										/>
									</div>
								</div>

								<div class="mb-3 row">
									<label for="dob" class="col-sm-4 col-form-label">Date of birth</label>
									<div class="col-sm-8">
										<input
											type="date"
											v-model="account.dob"
											class="form-control"
											id="dob"
											placeholder="enter your date of birth"
										/>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<button :disabled="updateLoading" class="btn btn-primary float-end">
											<font-awesome-icon :icon="['fas', updateLoading ? 'spinner':'']" :class="updateLoading ? 'fa-spin' : ''" />
											Update
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* import restApi from '../../api' */
import { mapState } from 'vuex'
import { handleErrors } from '../../actions/helpers'
export default {
	name: 'AccountEdit',
	components: {},
	data() {
		return {
			loading: false,
			updateLoading: false
		}
	},
	computed: {
		...mapState(['account', 'storageUrl']),
	},
	methods: {
		updateAccount() {
			this.updateLoading = true
			let data = {
				id: this.account.id,
				name: this.account.name,
				dob: this.account.dob,
				email: this.account.email,
			}
			this.$store
				.dispatch('accountUpdate', data)
				.then(() => {
					this.updateLoading = false
					this.$swal.fire({
						icon: 'success',
						title: 'Success',
						text: 'User successfully updated',
					})
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: handleErrors(error, 'userUpdate'),
						timer: 6000,
					})
				})
		},
	},
}
</script>
