<template>
	<div id="accountChangePassword">
		<div class="container lp-full-height">
			<div class="row justify-content-center">
				<div class="col-xl-6 col-lg-8 col-md-8 col-sm-12 my-5">
					<div class="account-item-navigation d-flex justify-content-start mb-2">
						<router-link to="/account" class="btn btn-outline-primary"
							><font-awesome-icon :icon="['fas', 'arrow-left']" /> Account</router-link
						>
					</div>
					<div class="account-item-image d-flex flex-column align-items-center justify-content-center mb-2">
						<img src="../../../public/images/account-change-password-header.svg" width="220" alt="" />
						<h4 class="mt-2 text-primary">Change password</h4>
					</div>

					<div class="card">
						<div class="card-body">
							<form @submit.prevent="changePassword">
								<div class="mb-3 row">
									<label for="currentPassword" class="col-sm-4 col-form-label"
										>Current Password</label
									>
									<div class="col-sm-8">
										<input
											type="password"
											v-model="currentPassword"
											class="form-control"
											id="currentPassword"
											placeholder="enter your old password"
											required
										/>
									</div>
								</div>

								<div class="mb-3 row">
									<label for="newPassword" class="col-sm-4 col-form-label">New Password</label>
									<div class="col-sm-8">
										<input
											type="password"
											v-model="newPassword"
											class="form-control"
											id="newPassword"
											placeholder="enter new password"
											required
										/>
									</div>
								</div>

								<div class="mb-3 row">
									<label for="confirmPassword" class="col-sm-4 col-form-label"
										>Confirm Password</label
									>
									<div class="col-sm-8">
										<input
											type="password"
											v-model="confirmPassword"
											class="form-control"
											id="confirmPassword"
											placeholder="enter password again"
											required
										/>
									</div>
								</div>
								<p class="text-center mt-4">
									<button class="btn btn-primary">Change password</button>
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import restApi from '../../api'
import { mapState } from 'vuex'
import { handleErrors } from '../../actions/helpers'
export default {
	name: 'ChangePassword',
	components: {},
	data() {
		return {
			loading: false,
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		}
	},
	computed: {
		...mapState(['account', 'storageUrl']),
	},
	methods: {
		changePassword() {
			this.loading = true
			let data = {
				current_password: this.currentPassword,
				new_password: this.newPassword,
				new_confirm_password: this.confirmPassword,
			}
			restApi
				.post('/account/change-password', data)
				.then(() => {
					this.loading = false
					this.$swal.fire({
						icon: 'success',
						title: 'Password successfully changed!',
					})
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: handleErrors(error, 'changePassword'),
						timer: 5000,
					})
				})
		},
	},
}
</script>
